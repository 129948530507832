.rems_circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #734392;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .phub_circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #005E82;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ADACAE;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .number {
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    font-family: 'BMSHumanity';
  }
  .dottedLine {
    font-size: 16px;
    font-weight: bold;
    color: #595454;
    font-family: 'BMSHumanity';
  }
  .dotteddiv {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }