.footer_container{
        display: "flex";
        flex-direction: "row";
        width: "100%";
        height: "37px";
        gap: "0px";
        opacity: "0px";
        background-color: "#E1D0EC";
        text-align: "center";
        justify-content: "center";
        align-content: "center";
        align-items: "center";
      
}